
import { defineComponent, ref, watch, onMounted, Ref } from "vue";
import useEmitter from "@/composables/Emmiter";
import FilterBase from "@/layout/header/partials/filters/FilterBase.vue";
import Api from "@/services/Api";
import ptBr from "element-plus/lib/locale/lang/pt-br";

interface LojasValidacaoJetoilFilter {
  cnpj: string;
  razao_social: string;
  estado: string;
  consultor: string;
}

export default defineComponent({
  name: "FilterLojasValidacaoJetoil",
  components: {
    FilterBase,
  },

  setup(props, { emit }) {
    const emitter = useEmitter();

    const closeModal = ref(false);
    const arrayEstados: Ref<any[]> = ref([]);
    const arrayCargos: Ref<any[]> = ref([]);

    const lojasValidacaoJetoilFilter: Ref<LojasValidacaoJetoilFilter> = ref({
      cnpj: "",
      razao_social: "",
      estado: "",
      consultor: "",
    });

    async function getEstados() {
      const { data } = await Api.get("getEstadosLojasValidacaoJetoil");
      return data
    }

    function enviarEmit() {
      emitter.emit("Filter-LojasValidacaoJetoil", lojasValidacaoJetoilFilter.value );
      closeModal.value = !closeModal.value;
    }

    // watch(
    //   ()=> lojasValidacaoJetoilFilter.value,
    //   ()=> console.log('userIpirangaFilter', lojasValidacaoJetoilFilter.value),
    //   {deep: true}
    // )


    onMounted(() => {
      Promise.all([getEstados()]).then((values) => {
        arrayEstados.value = values[0];
      });
    });

    return { lojasValidacaoJetoilFilter, arrayEstados, arrayCargos, enviarEmit, closeModal, ptBr };
  },
});
